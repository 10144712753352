import { LitElement, html } from 'lit-element';

class FullcalendarWebcomponent extends LitElement {

  //@internalProperty({ type: Object })
  calendar = null;
  config_type = null;
  highlighting_item_id = null;
  ext_events_initialized = false;

  // Declare observed properties
  static get properties() {
    return {
      reload: {},
    }
  }

  render() {
    return html`
    <div></div>
    `;
  }

  calendarElement() {
    return this.querySelector(':scope > div');
  }

  disposePopovers() {
    // Deactivate popover before reload (otherwise popover may get stuck and will never hide)
    $(".fc-event", this.calendarElement()).popover("dispose");
  }

  scheduleHighlightEvent(event_date, item_id) {
    this.highlighting_item_id = item_id;
    if (this.calendar == undefined) return;

    var that = this;
    this.calendar.batchRendering(function() {
      that.calendar.changeView('timeGridWeek');
      // we have to set the date here, as otherwise the event is not loaded
      // if it does not fit in current calendar view
      that.calendar.gotoDate(event_date);
      that.refresh();
    });
  }

  possiblyHighlightEvent(info) {
    if (!this.highlighting_item_id) return;
    if (this.highlighting_item_id != info.event.id) return;

    this.highlighting_item_id = null;
    var event_start_time = this.getDateString(info.event.start).split('T')[1];
    var start_time = event_start_time < "07:00:00" ? event_start_time : "07:00:00"
    start_time = event_start_time > "19:00:00" ? event_start_time : start_time
    this.calendar.scrollToTime(start_time)
    $(info.el).effect( "shake", { times: 3, distance: 10 } );
  }

  getDateString(dateObj) {
    // Calender will format date object without timezone info
    // because FullCalender creates Date objects with fake UTC
    // even if dates are sent with timezone information
    // (use of momentPlugin could fix this)
    return this.calendar.formatIso(dateObj);
  }

  refresh() {
    this.disposePopovers();
    var cal = this.calendar;
    cal.batchRendering(function() {
      var events = cal.getEvents();
      $.each(events, function(index, ev) {
	// disallow moving / resizing while refetching
	ev.setProp("editable", false);
      });
      cal.refetchEvents();
    });
  }

  redraw() {
    this.calendar.render();
  }

  updated(changedProperties) {
    changedProperties.forEach((_old_value, prop_name) => {
      if (prop_name == "reload") {
        if (this.config_type != "machines")
          this.show_machine_reservations(this.run_id, this.machine_id);
	if (this.config_type == "queues")
          this.show_run_reservations(this.run_id);
        this.calendar.render();
        if (this.reservation_id > 0) {
          this.scheduleHighlightEvent(this.reservation_date, this.reservation_id);
        }
      }
    });

    if (this.id && !this.ext_events_initialized) {
      this.ext_events_initialized = true;

      var ext_events_areas = document.querySelectorAll("[data-ext-events='" + this.id + "']")
      var ext_events_area = ext_events_areas[0]; // for now only one area!
      if (!ext_events_area) return;

      new FullCalendarDraggable(ext_events_area, {
        itemSelector: '.fc-extEvent'
      });
    };
  }

  firstUpdated() {
    var node = this;
    var date = this.getAttribute('date');
    this.config_type = this.getAttribute('config_type');

    var config = null;
    switch (this.config_type) {
    case "machines":
      var machine_id = this.getAttribute('machine_id');
      var allow_new = this.getAttribute('allow_new') == "true";
      var on_change = function() {
	$('#event-list').load(Routes.machine_reservations_path(
	  machine_id,
	  { start: node.calendar.view.currentStart, end: node.calendar.view.currentEnd }
	), function() {
	  common.init_js_controls($('#event-list'));
	});
      }
      config = fc_extras.fcMachineReservationConfig(
	node, date, on_change, machine_id, allow_new
      );
      break;
    case "runs":
      this.run_id = this.getAttribute('run_id');
      config = fc_extras.fcRunReservationConfig(
	node, date, undefined, this.run_id
      );
      break;
    case "queues":
      // var queue_id = this.getAttribute('queue_id');
      config = fc_extras.fcQueueReservationConfig(
	node, date, undefined
      );
      break;
    case "tasks":
      var user_id = this.getAttribute('user_id');
      var readonly = this.getAttribute('readonly') == "true";
      var on_change = function() {
	$('#event-list').load(Routes.events_user_path(
	  user_id,
	  { start: node.calendar.view.currentStart, end: node.calendar.view.currentEnd }
	), function() {
	  common.init_js_controls($('#event-list'));
	});
	$('#tasks-stats').load(Routes.tasks_stats_user_path(
	  user_id,
	  { start: node.calendar.view.currentStart, end: node.calendar.view.currentEnd }
	), function() {
	  common.init_js_controls($('#tasks-stats'));
	});
      }
      config = fc_extras.fcTasksConfig(
	node, date, on_change, user_id, readonly
      );
      break;
    default:
      alert("No such config_type:" + this.config_type)
    }

    this.calendar = new FullCalendar(this.calendarElement(), config);
    this.calendar.render();
  }

  createRenderRoot() {
    /**
     * Render template in light DOM. Note that shadow DOM features like
     * encapsulated CSS are unavailable.
     */
    return this;
  }

  fireDataChanged() {
    var ev = new CustomEvent("data-changed");
    this.dispatchEvent(ev);
  }

  setMachineId(machine_id, run_id) {
    this.machine_id = parseInt(machine_id);
    this.run_id = parseInt(run_id);
    var ev = new CustomEvent("machine-selected", {
      detail: {
        machine_id: this.machine_id
      },
    });
    this.dispatchEvent(ev);
  }

  show_machine_reservations(run_id, machine_id) {
    var eventSources = this.calendar.getEventSources();
    $.each(eventSources, function(index, es) {
      if (es.id.startsWith("machine-"))
	es.remove();
    });
    if (!machine_id) return;

    var path = Routes.machine_reservations_path(
      machine_id, { format: "json", selected_run_id: run_id }
    );
    this.calendar.addEventSource(
      {
        id: "machine-" + machine_id,
        url: path,
        className: "type-machine",
        editable: true
      }
    );
  }

  show_run_reservations(run_id) {
    var eventSources = this.calendar.getEventSources();
    $.each(eventSources, function(index, es) {
      if (es.id.startsWith("run-"))
	es.remove();
    });
    if (!run_id) return;

    this.calendar.addEventSource(
      {
        id: "run-" + run_id,
        url: Routes.run_reservations_path(run_id, { format: "json" }),
        className: "type-run",
        editable: true
      }
    );
  }

}
customElements.define('fullcalendar-webcomponent', FullcalendarWebcomponent);
