// define custom popover sanitizer
// https://getbootstrap.com/docs/4.3/getting-started/javascript/#sanitizer
var popoverWhiteList = Object.assign({}, $.fn.popover.Constructor.Default.whiteList, {
  dl: [],
  dt: [],
  dd: [],
  table: [ "class" ],
  tbody: [],
  tr: [],
  th: [],
  td: [],
});

module.exports = { popoverWhiteList };
