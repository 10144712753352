$(document).ready(function() {

  // stickytable
  $(window).resize(function() {
    $(".sticky-table").scroll();
  });
  $(document).trigger("stickyTable");

  // bootstrap modals
  $('body').on('hidden.bs.modal', '.modal', function() {
    $(this).removeData('bs.modal').find(".modal-content").empty();
    /* Workaround for scrolling issues on a first modal if a second modal is closed */
    /* see https://stackoverflow.com/questions/36460538/scrolling-issues-with-multiple-bootstrap-modals */
    $('.modal:visible').length && $(document.body).addClass('modal-open');
  });

  // dashboard functions
  $('.dashboard-card .collapse').on('show.bs.collapse', function() {
    $(this).parent().find(".fa-chevron-right").toggleClass("fa-chevron-down fa-chevron-right");
    update_profile_ui_config("dashboard", $(this).parent().attr('data-card'), "show", true)
  });

  $('.dashboard-card .collapse').on('hide.bs.collapse', function() {
    $(this).parent().find(".fa-chevron-down").toggleClass("fa-chevron-down fa-chevron-right");
    update_profile_ui_config("dashboard", $(this).parent().attr('data-card'), "show", false)
  });

  // fix scrolling issue with multiple modals
  // https://stackoverflow.com/questions/36460538/scrolling-issues-with-multiple-bootstrap-modals
  $("body").on('hidden.bs.modal', function() {
    if ($('.modal:visible').length) {
      $('body').addClass('modal-open');
    }
  });

  // run filter submit on select
  $('#run-filter #number').change(function() {
    $('#run-filter #costcenter_id').val("");
    $('#run-filter #owner_id').val("");
    $('#run-filter #mentor_id').val("");
    $('#run-filter').submit();
  });

  $('#run-filter select').change(function() {
    $('#run-filter #number').val("");
    $('#run-filter').submit();
  });

  // recipes filter submit on select
  $('#recipes-filter select, #recipes-filter input').change(function() {
    $('#recipes-filter').submit();
  });

  // parameters filter submit on select
  $('#parameters-filter select, #parameters-filter input').change(function() {
    $('#parameters-filter').submit();
  });

  // parameter_groups filter submit on select
  $('#parameter-groups-filter select').change(function() {
    $('#parameter-groups-filter').submit();
  });

  // material filter submit on select
  $('#material-filter select, #material-filter input').change(function() {
    $('#material-filter').submit();
  });

  init_js_controls();
  init_modal('.modal');
});

function init_js_controls(container) {
  // bootstrap tooltip
  // boundary is needed for correct positioning
  // see https://getbootstrap.com/docs/4.1/components/tooltips/#usage
  $('[data-toggle="tooltip"]', container && container.parentNode).tooltip({ boundary: 'window' });

  init_copyable();
  init_modal_links(container);
  init_clickable(container);
  fix_file_fields(container);
  init_comboboxes(container);
  initialize_bootstrap_popover(container);
  dt_picker_extras.init_datetimepicker(container);
  remote_forms.init_ajax_forms(container);
  fc_extras.redraw_fullcalendar();
}

function init_modal(modal) {
  $(modal).on('show.bs.modal', function() {
    // call here earlier so that some controls are already
    // initialized before user sees the dialog
    // IMPORTANT: this event is to early to init_ajax_forms()
    init_comboboxes($(this).find(".modal-content"));
    // hide all tooltips
    $('[data-toggle="tooltip"]', document).tooltip('hide');
  });

  $(modal).on('shown.bs.modal', function() {
    $(".popover").popover('hide');
    $(".run-editor-container .modal").parent().hide();
    init_js_controls($(this).find(".modal-content"));
  });

  $(modal).on('hidden.bs.modal', function(e) {
    $(".run-editor-container .modal").parent().show();
    e.currentTarget.remove();
  });
}

function init_modal_links(container) {
  // clicking <el data-modal-close></el> will close the enclosing modal instantly
  $("a[data-modal-close], button[data-modal]", container).on("click", function(ev) {
    var modal = $(ev.currentTarget).closest(".modal");
    modal.removeClass("fade");
    modal.modal("hide");
  });

  $("form[data-modal-close]", container).on("submit", function(ev) {
    var modal = $(ev.currentTarget).closest(".modal");
    modal.removeClass("fade");
    modal.modal("hide");
  });

  // clicking <el href="link" data-modal data-modal-size="size" data-target="id"></el
  // will open a modal of chosen size and id (both optional)
  // the link is given in the href or data-href attribute
  $("a[data-modal], button[data-modal]", container).on("click", function(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.currentTarget.dataset["confirm"] && !confirm(ev.currentTarget.dataset["confirm"]))
      return false;

    var href = ev.currentTarget.dataset["href"] || ev.currentTarget.href;
    openModal(href,
	      ev.currentTarget.dataset["modalId"],
	      ev.currentTarget.dataset["modalSize"],
	      ev);
  });

  $("form[data-modal]", container).on("submit", function(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.currentTarget.dataset["confirm"] && !confirm(ev.currentTarget.dataset["confirm"]))
      return false;

    var form_data = new FormData(this);
    var params = {};
    var url = this.action;

    if (this.method == "post") {
      // copy button value to params
      params[ev.originalEvent.submitter.name] = ev.originalEvent.submitter.value;
      // populate params
      form_data.forEach((value, key) => (params[key] = value));
    } else {
      // only GET supported
      params = false;
      var get_params = (new URLSearchParams(form_data)).toString();
      url = url + (url.includes("?") ? "&" : "?") + get_params;
    }

    openModal(url,
	      this.dataset["modalId"],
	      this.dataset["modalSize"],
	      ev,
	      params); // assume POST for forms
  });
}

// copy text buttons
function init_copyable() {
  $(".js-copy").on("click", function() {
    navigator.clipboard.writeText(this.dataset.content);
    $(this).removeClass("text-secondary");
    $(this).addClass("text-success");
    var that = $(this);
    setTimeout(function() {
      that.addClass("text-secondary");
      that.removeClass("text-success");
    }, 100);
  });
}

// clickable table rows
function init_clickable(container) {
  var selector = $('.clickable', container);
  if (container && container.hasClass("clickable"))
    selector = container;
  selector.off("click");
  selector.click(function(event) {
    var url = $(this).data('href');
    var modal_id = $(this).data('modal-id');
    var size = $(this).data('modal-size');
    var modal = ($(this).data('modal') !== undefined);
    if (modal && url) {
      return openModal(url, modal_id, size)
    }
    if (url)
      window.location = url;
  });
}

function initialize_bootstrap_popover(container) {
  $('[data-toggle="popover"]', container).popover();
  $('[data-toggle="popover-element"]', container)
    .popover({ content: function() { return $($(this).data().element).html(); } })
    .on("show.bs.popover", function() {
      $($(this).data("bs.popover").tip).addClass($(this).data().customclass);
    });
}

function fix_file_fields(container) {
  // WORKAROUND: for showing chosen filename in file_field input
  // https://stackoverflow.com/questions/48613992/bootstrap-4-file-input-doesnt-show-the-file-name
  $('input[type=file]', container).on('change',function(){
    //get the file name
    var file_name = $(this).val();
    //replace the "Choose a file" label
    file_name = file_name.substring(file_name.lastIndexOf("\\") + 1, file_name.length);
    $(this).next('.custom-file-label').html(file_name);
  });
}

function loadModalContent(href, modal, callback, post) {
  href = href + (href.includes("?") ? "&" : "?") + "modal=1";
  // FIXME: use waiting spinner, global method
  $("html, body").css("cursor", "progress");
  $(modal).find(".modal-content").load(href, post, function() {
    callback();
    $("html, body").css("cursor", "default");
  });
  return false; // prevent default click event
}

function update_profile_ui_config(namespace, key, attrib, value) {
  data = { ui_config : {} }
  data["ui_config"][namespace] = {}
  data["ui_config"][namespace][key] = {}
  data["ui_config"][namespace][key][attrib] = value

  $.ajax({
    type: "PUT",
    url: Routes.profile_path(),
    dataType: "json",
    contentType: "application/json",
    data: JSON.stringify(data)
  });
}

function createModal(modal_id, size) {
  if (!size)
    size = "lg";
  var modal = $('<div class="modal fade" role="dialog">' +
                  '<div class="modal-dialog modal-' + size + '">' +
                      '<div class="modal-content"></div></div></div>');
  if (modal_id)
    modal.prop("id", modal_id);
  $("#modal-anchor").append(modal);
  init_modal(modal);

  return modal;
}

function openModal(href, modal_id, size, event, post_params) {
  // prevent propagation of click event to parent elements
  if (event) event.stopPropagation();

  if (!modal_id || !document.getElementById(modal_id))
    var modal = createModal(modal_id, size);

  loadModalContent(href, modal, function() {
    $(modal).modal({ show: true, keyboard: false });
  }, post_params);

  return false; // prevent default click event
}

function openNewMaterialLotModal(material_type_id, material_delivery_id, source_id, event) {
  if (material_type_id) {
    var url = Routes.new_material_delivery_material_lot_path({ material_delivery_id: material_delivery_id, "material_lot[material_type_id]": material_type_id, source_id: source_id });
    openModal(url, "new-material-lot-modal", null, event);
  } else {
    alert("Bitte Materialtyp wählen!");
  }

  return false; // prevent default click event
}

function openParameterGroupConfigurationModal(select_id, object_id, object_type, object_scope, source_id) {
  var parameter_group_id = null;
  if (select_id)
    parameter_group_id = $(select_id).val();

  // do nothing if blank entry (prompt) chosen or not found
  if (!parameter_group_id && !source_id)
    return false;

  var action = Routes.new_parameter_group_configuration_path(
    { object_id: object_id, object_type: object_type, object_scope: object_scope,
      parameter_group_id: parameter_group_id, source_id: source_id }
  );
  openModal(action, "parameter-group-configuration-modal", "xl");

  return false; // prevent default click event
}

// Close the modal showing a reservation or task and
// possibly refresh the data in fullcalendar and in RUN-Editor
function close_modal_refresh_calendar() {
  // reload RUN editor (if active)
  run_editor_extras.refresh_run_editor();

  fc_extras.refresh_fullcalendar();
  $('#modal-anchor .modal').last().modal('hide');
}

function close_modal(button) {
  $(button).closest(".modal").modal("hide");
}

function refresh(klass) {
  $(klass).each(function() {
    // replace children of selected element
    var url = this.dataset.url + " " + klass + " > *";
    $(this).load(url, function() {
      init_js_controls($(klass));
    });
  });
}

/* comboboxes with select2
 *
 * IMPORTANT: dropdownParent has to be .modal-content in modals
 *
 */
function init_comboboxes(container) {
  // WORKAROUND: Override the default matcher to not strip DIACRITICS (Umlaute)
  //             function is copied from
  //             https://github.com/select2/select2/blob/master/src/js/select2/defaults.js
  //             also see:
  //             https://github.com/select2/select2/issues/4007
  function matcher (params, data) {
    // Always return the object if there is nothing to compare
    if ($.trim(params.term) === '') {
      return data;
    }

    // Do a recursive check for options with children
    if (data.children && data.children.length > 0) {
      // Clone the data object if there are children
      // This is required as we modify the object to remove any non-matches
      var match = $.extend(true, {}, data);

      // Check each child of the option
      for (var c = data.children.length - 1; c >= 0; c--) {
        var child = data.children[c];

        var matches = matcher(params, child);

        // If there wasn't a match, remove the object in the array
        if (matches == null) {
          match.children.splice(c, 1);
        }
      }

      // If any children matched, return the new object
      if (match.children.length > 0) {
        return match;
      }

      // If there were no matching children, check just the plain object
      return matcher(params, match);
    }

    var original = data.text.toUpperCase();
    var term = params.term.toUpperCase();

    // Check if the text contains the term
    if (original.indexOf(term) > -1) {
      return data;
    }

    // If it doesn't contain the term, don't return anything
    return null;
  }

  $(".combobox", container).each(function() {
    var width = "resolve";
    if ($(this).parent().find(".input-group-prepend").length)
      width = "75%"; // fix combobox with prepended icon
    $(this).select2({
      theme: "bootstrap4",
      width: width,
      placeholder: "Bitte wählen …",
      dropdownParent: container,
      matcher: matcher
    });
    var that = this;
    $(this).on("change", function() {
      Rails.handleRemote.call(that, event);
    });
  });
}

module.exports = {
  initialize_bootstrap_popover,
  loadModalContent,
  openModal,
  openNewMaterialLotModal,
  openParameterGroupConfigurationModal,
  close_modal,
  close_modal_refresh_calendar,
  init_js_controls,
  init_clickable,
  refresh
}
