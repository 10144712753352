function isEqual(obj1, obj2) {
  var props1 = Object.getOwnPropertyNames(obj1);
  var props2 = Object.getOwnPropertyNames(obj2);    if (props1.length != props2.length) {
    return false;
  }    for (var i = 0; i < props1.length; i++) {
    let val1 = obj1[props1[i]];
    let val2 = obj2[props1[i]];
    let isObjects = isObject(val1) && isObject(val2);        if (isObjects && !isEqual(val1, val2) || !isObjects && val1 !== val2) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}

function init(parameter_type, material_type_id, material_delivery_id, run_id, item_id, param_id, group_id, show_deleted_empty, data_columns, filter) {

  $("html, body").css("cursor", "progress");

  if (!material_delivery_id)
    material_delivery_id = "";

  var last_height = 0;

  $(window).resize(function() {
    resize();
  });

  function table_height() {
    /* offset for page */
    var offset = 80
    if ($('#material-list.on-modal').length > 0)
      offset = 130;
    return $(window).height() - $('#material-list')[0].getBoundingClientRect().top - offset;
  }

  function resize() {
    var height = table_height();
    if (height != last_height) {
      console.log("resizing material_lot table to height: " + height);
      $(".col-table div.dataTables_scrollBody").css('max-height', height);
      $(".col-table div.dataTables_scrollBody").css('height', height);
      last_height = height;
      $('#material-lots-table').DataTable().draw();
    }
  }

  var url = null;
  if (run_id) {
    url = Routes.run_materials_path(run_id, {
      format: "json",
      material_type_id: material_type_id,
      material_delivery_id: material_delivery_id,
      show_deleted_empty: show_deleted_empty,
      item_id: item_id,
      param_id: param_id,
      group_id: group_id
    });
  } else {
    url = Routes.materials_path({
      format: "json",
      material_type_id: material_type_id,
      material_delivery_id: material_delivery_id,
      show_deleted_empty: show_deleted_empty
    });
  }

  var pre_select = [];
  Object.entries(filter).forEach(([key, value], _index) => {
    var col_index =
	data_columns.map((o) => o.data).indexOf(key + ".content");
    pre_select.push({ column: col_index, rows: value });
  });

  var table = $('#material-lots-table').DataTable({
    ajax: url,
    columnDefs: [
      {
	targets: '_all',
	createdCell: function (td, cellData, rowData, row, col) {
	  let className = rowData[Object.keys(rowData)[col]]["className"];
	  if (className) $(td).addClass(className);
	},
	searchPanes: {
	  initCollapsed: true,
          show: true,
        },
      },
    ],
    stateSave: false,
    columns: data_columns,
    searchPanes: {
      //initCollapsed: true,
      preSelect: pre_select,
      orderable: false,
      viewTotal: false,
      clear: false,
      layout: 'columns-1'
    },
    xxinitComplete: function() {
      this.api().searchPanes.rebuildPane();
    },
    search: {
      return: true,
    },
    serverSide: true,
    processing: true,
    deferRender: true,
    paging: true,
    pageLength: 50,
    destroy: true,
    scrollY: 0,
    scrollX: true,
    scrollCollapse: true,
    fixedColumns: {
      leftColumns: 2,
      heightMatch: 'auto'
    },
    buttons: [
      { extend: 'colvis', text: 'Spalten' },
      { extend: 'csv', text: 'CSV Export' },
    ],
    ordering: true,
    orderMulti: true,
    order: [[0, "desc"]],
    dom: '<"row"<"col-2 col-panes"P><"col-10 col-table"rt<"row"<"col"pi><"col text-right"B>>>',
    language: {
      decimal:        ",",
      thousands:      ".",
      emptyTable:     "Keine Daten",
      info:           "_START_ bis _END_ von _TOTAL_",
      infoEmpty:      "Keine Ergebnisse",
      infoFiltered:   "(aus _MAX_ Einträgen)",
      infoPostFix:    "",
      lengthMenu:     "Show _MENU_ entries",
      loadingRecords: "Lade...",
      processing:     "Verarbeite...",
      search:         "Filter:",
      zeroRecords:    "Keine passenden Einträge gefunden",
      paginate: {
        first:      "Anfang",
        last:       "Ende",
        next:       "»",
        previous:   "«"
      },
      aria: {
        sortAscending:  ": activate to sort column ascending",
        sortDescending: ": activate to sort column descending"
      },
      searchPanes: {
        title: 'Aktive Filter: %d',
	loadMessage: 'Lade Filter ...',
	clearMessage: 'Reset',
	showMessage: 'Auf',
	collapseMessage: 'Zu',
      }
    }
  });
  resize();

  table.searchPanes();
  table.on('xhr', function () {
    var data = table.ajax.params();
    var query = {};
    var search_panes = Object.entries(data.searchPanes);
    search_panes.forEach(([key, value], index) => {
      // prepare filter query
      var values = Object.values(value);
      if (values.length > 0)
	query[key.split(".")[0]] = values;
      // mark active panes
      $(`.dtsp-searchPanes > .dtsp-searchPane:nth-child(${index + 1})`).removeClass("pane-active");
      Object.entries(value).forEach(([key, value], index2) => {
	$(`.dtsp-searchPanes > .dtsp-searchPane:nth-child(${index + 1})`).addClass("pane-active");
      });
    });

    // set filter if param is a planning param and filter changed
    if (search_panes.length > 0 &&
	parameter_type == "planning" &&
	!isEqual(query, filter)
       ) {
      run_editor_app.ports.setMaterialFilter.send(
	[
	  item_id,
	  param_id,
	  { group_id: group_id, filter: JSON.stringify(query) }
	]
      );
      filter = query;
    }
  });

  //$("#material-list .dtsp-search-panes").append(table.searchPanes.container());
  $('#material-list').removeClass("invisible");
  $("html, body").css("cursor", "default");
}

module.exports = { init }
