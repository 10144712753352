/*
 * possibly refreshes any fullcalendar found on page (for reservations and tasks)
 */
function refresh_fullcalendar() {
  $('fullcalendar-webcomponent').each(function() { this.refresh() })
}

/*
 * redraw any fullcalendar (e.g. if it was created before page was fully loaded)
 */
function redraw_fullcalendar() {
  $('fullcalendar-webcomponent').each(function() { this.redraw() })
}

function gotoDate(iso_date) {
  $('fullcalendar-webcomponent').each(function() { this.calendar.gotoDate(iso_date) })
}

function event_change(calendar_component, ev, type, revertFunc) {
  var startStr = calendar_component.getDateString(ev.start);
  var endStr = calendar_component.getDateString(ev.end);
  var data = {};
  data[type] = { start_at: startStr, stop_at: endStr }
  $.ajax({
    url: ev.url + '.json',
    data: data,
    method: "PUT",
    context: document.body
  }).done(function(data, status, xhr) {
    if (data.replace) {
      for (var id in data.replace) {
        $(id).replaceWith(data.replace[id]);
        common.init_js_controls($(id));
      }
    }
    calendar_component.refresh();
    calendar_component.fireDataChanged();
  }).fail(function(xhr, status, error) {
    var msg = "Fehler beim Speichern /";
    try {
      errors = $.parseJSON(xhr.responseText);
      $.each(errors, function(field, messages) {
        msg = msg + "/ " + field + ": " + messages.join(', ');
      });
    } catch (exc) {
      msg += " (Konnte Fehlermeldung nicht lesen) ";
      console.error("Fehler beim Lesen der Antwort: " + exc + "\nInhalt der Antwort: \n" + xhr.responseText);
    }
    msg += " / Kalender wird neu geladen...";
    // $(this).render_form_errors($.parseJSON(xhr.responseText));
    alert(msg);
    calendar_component.refresh();
  });
}

function addPopover(info) {
  var ev = info.event;
  if (ev.extendedProps.popover_title === undefined
      || ev.extendedProps.popover_content === undefined) {
    // don't add popover if title or content is missing
    return;
  }
  var el = $(info.el);
  var placeBottom = ev.allDay;
  var placeLeft = !placeBottom && (info.view.currentStart < ev.start && (ev.start.getDay() > 4 || ev.start.getDay() == 0));

  el.popover({
    content: ev.extendedProps.popover_content,
    title: ev.extendedProps.popover_title,
    html: true,
    // our custom whitelist
    whiteList: popoverWhiteList,
    trigger: "hover",
    container: "body",
    placement: placeBottom ? "bottom" : (placeLeft ? 'left' : 'right'),
    // https://getbootstrap.com/docs/4.5/components/popovers/#usage
    // add custom class to popover
    template: '<div class="popover no-pointer-events" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
  });
}

function fcDefaultConfig(initial_date, datesRenderCallback) {
  if (initial_date === undefined)
    initial_date = (new Date()).toISOString();

  return {
    loading: function(isLoading) {
      // FIXME: add a spinner
      if (!isLoading && datesRenderCallback !== undefined)
	datesRenderCallback();
    },
    eventClick: function(info) {
      info.jsEvent.preventDefault();
      var url = info.event.url;
      common.openModal(url);
    },
    initialDate: initial_date,  // pass default date in case local date is wrong
    timeZone: 'Europe/Berlin', // always use German timezone to avoid time shifting
                               // if ibook is used in other countries
    height: 650,
    locales: [ calendar_de ],
    locale: 'de',
    eventDisplay: "block",
    allDayContent: '',
    weekNumbers: true,
    scrollTime: '7:00',
    headerToolbar: {
      left: 'title',
      center: '',
      right: 'dayGridMonth,timeGridWeek prev,next today'
    },
    slotDuration: '00:30:00',
    snapDuration: '00:30:00',
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short'
    },
    plugins: [ dayGridPlugin , timeGridPlugin, interactionPlugin, bootstrapPlugin ],
    themeSystem: 'bootstrap',
    initialView: 'timeGridWeek',
    editable: true,
    droppable: true,
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false
    },
    businessHours: {
      // days of week. an array of zero-based day of week integers (0=Sunday)
      daysOfWeek: [ 1, 2, 3, 4, 5 ],

      startTime: '08:00', // a start time (10am in this example)
      endTime: '18:00', // an end time (6pm in this example)
    }
  }
};

////////////////////////////////////////////////////////////////////////////////
//                        Machine reservations
////////////////////////////////////////////////////////////////////////////////

/*
 * Return config dictionaries
 * Parameters:
 *   paths for eventSources
 *   path for new reservation
 *   machine_id for new reservation
 *   allowNew if user is allowed to create new reservations
 */
function fcMachineReservationConfig(calendar_component, initial_date, datesRenderCallback, machine_id, allowNew) {
  config = {
    eventSources: [
    {
      id: "machine",
      url: Routes.machine_reservations_path(machine_id, { format: "json" }),
      className: "type-run",
      editable: true
    },
    {
      id: 'holidays-feed',
      url: Routes.holidays_path({ format: "json" }),
      className: 'type-holiday',
      defaultAllDay: true,
      editable: false
    }
    ],
    eventDidMount: function(info) {
      if (info.isMirror) return; // Don't show popup while moving events

      addPopover(info);
    },
    eventOverlap: true,
    eventDrop: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    eventResize: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    editable: true,
    selectable: true,
    slotDuration: '00:30:00',
    snapDuration: '00:30:00',
    selectMirror: true,
    selectMinDistance: 2,
    select: function(selectInfo) {
      common.openModal(Routes.new_reservation_path(
	{
          'reservation[start_at]': selectInfo.startStr,
          'reservation[stop_at]': selectInfo.endStr,
          'reservation[machine_id]': machine_id
	}
      ))
    },
    selectAllow: function(event) {
      return allowNew;
    },
    eventOrder: "end,start,-duration,allDay,title",
    eventOrderStrict: true
  }

  return $.extend(fcDefaultConfig(initial_date, datesRenderCallback), config);
}

////////////////////////////////////////////////////////////////////////////////
//                        RUN reservations
////////////////////////////////////////////////////////////////////////////////

/*
 * Return config dictionaries
 * Parameters:
 *   paths for eventSources
 */
function fcRunReservationConfig(calendar_component, initial_date, datesRenderCallback, run_id) {
  config = {
    eventSources: [
    {
      id: "run",
      url: Routes.run_reservations_path(run_id, { format: "json" }),
      className: "type-run",
      editable: true
    },
    {
      id: 'holidays-feed',
      url: Routes.holidays_path({ format: "json" }),
      className: 'type-holiday',
      defaultAllDay: true,
      editable: false
    }
    ],
    eventDidMount: function(info) {
      if (info.isMirror) return; // Don't show popup while moving events

      addPopover(info);
      calendar_component.possiblyHighlightEvent(info);
    },
    eventDragStart: function(info) {
      if (info.event.extendedProps.machine_id)
        calendar_component.setMachineId(
            info.event.extendedProps.machine_id,
            info.event.extendedProps.run_id);
    },
    eventOverlap: true,
    eventDrop: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    eventResizeStart: function(info) {
      if (info.event.extendedProps.machine_id)
        calendar_component.setMachineId(
            info.event.extendedProps.machine_id,
            info.event.extendedProps.run_id);
    },
    eventResize: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    eventReceive: function(info) {
      var start_at = calendar_component.getDateString(info.event.start);
      var stop_at = calendar_component.getDateString(info.event.end);
      common.openModal(Routes.new_reservation_path(
	{
	  'reservation[start_at]':    start_at,
	  'reservation[stop_at]':     stop_at,
	  'reservation[run_id]':      info.event.extendedProps.run_id,
	  'reservation[run_item_id]': info.event.extendedProps.run_item_id,
	  'reservation[machine_id]':  info.event.extendedProps.machine_id
	}
      ));
      info.event.remove(); // remove event in case new reservation dialog is cancelled
    },
    eventOrder: "end,start,-duration,allDay,title",
    eventOrderStrict: true,
  }
  return $.extend(fcDefaultConfig(initial_date, datesRenderCallback), config);
}

////////////////////////////////////////////////////////////////////////////////
//                        Queue reservations
////////////////////////////////////////////////////////////////////////////////

/*
 * Return config dictionaries
 * Parameters:
 *   paths for eventSources
 */
function fcQueueReservationConfig(calendar_component, initial_date, datesRenderCallback) {
  config = {
    eventSources: [
    {
      id: 'holidays-feed',
      url: Routes.holidays_path({ format: "json" }),
      className: 'type-holiday',
      defaultAllDay: true,
      editable: false
    }
    ],
    eventDidMount: function(info) {
      if (info.isMirror) return; // Don't show popup while moving events

      addPopover(info);
      calendar_component.possiblyHighlightEvent(info);
    },
    eventDragStart: function(info) {
      if (info.event.extendedProps.machine_id)
        calendar_component.setMachineId(
            info.event.extendedProps.machine_id,
            info.event.extendedProps.run_id);
    },
    eventOverlap: true,
    eventDrop: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    eventResizeStart: function(info) {
      if (info.event.extendedProps.machine_id)
        calendar_component.setMachineId(
            info.event.extendedProps.machine_id,
            info.event.extendedProps.run_id);
    },
    eventResize: function(info) {
      event_change(calendar_component, info.event, 'reservation', info.revert);
    },
    eventReceive: function(info) {
      var start_at = calendar_component.getDateString(info.event.start);
      var stop_at = calendar_component.getDateString(info.event.end);
      common.openModal(Routes.new_reservation_path(
	{
	  'reservation[start_at]':    start_at,
	  'reservation[stop_at]':     stop_at,
	  'reservation[run_id]':      info.event.extendedProps.run_id,
	  'reservation[run_item_id]': info.event.extendedProps.run_item_id,
	  'reservation[machine_id]':  info.event.extendedProps.machine_id
	}
      ));
      info.event.remove(); // remove event in case new reservation dialog is cancelled
    },
    eventOrder: "end,start,-duration,allDay,title",
    eventOrderStrict: true,
  }
  return $.extend(fcDefaultConfig(initial_date, datesRenderCallback), config);
}

////////////////////////////////////////////////////////////////////////////////
//                        Tasks
////////////////////////////////////////////////////////////////////////////////

function fcTasksConfig(calendar_component, initial_date, datesRenderCallback, user_id, readonly) {
  config = {
    eventSources: [
      Routes.events_user_path(user_id, { readonly: readonly, format: "json" }),
      {
        id: 'user-absent-feed',
        url: Routes.absent_user_path(user_id, { format: "json" }),
        className: 'type-absence',
        defaultAllDay: true,
        editable: false
      },
      {
        id: 'user-schedule-feed',
        url: Routes.user_calendar_schedule_path(user_id, { format: "json" }),
        className: 'type-schedule',
        defaultAllDay: true,
        // overlap: false, // FIXME should work, but does not, we have to set it on the object
        editable: false
      },
      {
        id: 'holidays-feed',
        url: Routes.holidays_path({ format: "json" }),
        className: 'type-holiday',
        defaultAllDay: true,
        editable: false
      }
    ],
    eventDidMount: function(info) {
      if (info.isMirror) return; // Don't show popup while moving events

      addPopover(info);
    },
  };

  if (!readonly) {
    $.extend(config, {
      editable: true,
      selectable: true,
      slotDuration: '00:30:00',
      snapDuration: '00:15:00',
      selectMirror: true,
      selectMinDistance: 2,
      eventOverlap: function(event, moving) {
        // BUGFIX: overlap does not work
	if (!event.id) return true;
	// tasks and absences can overlap reservations
	if (event.extendedProps.ibook_type === "reservation") return true
	// same types can not overlap
	if (event.extendedProps.ibook_type === moving.extendedProps.ibook_type) return false
	// task can overlap with absences if allowed
	if (moving.extendedProps.ibook_type === "task" &&
	    event.extendedProps.ibook_type === "absence" &&
	    event.extendedProps.ibook_overlap) return true
	// absence can overlap with task if allowed
	if (moving.extendedProps.ibook_type === "absence" &&
	    event.extendedProps.ibook_type === "task" &&
	    moving.extendedProps.ibook_overlap) return true
	return false
      },
      selectOverlap: function(event) {
        // BUGFIX: overlap does not work (sometimes yes)
	if (!event.id) return true;
        return event.overlap || event.extendedProps.ibook_overlap
      },
      eventDrop: function(event) {
        event_change(calendar_component, event.event, 'task', event.revert);
      },
      eventResize: function(event) {
        event_change(calendar_component, event.event, 'task', event.revert);
      },
      select: function(selectInfo) {
        common.openModal(Routes.new_user_task_path(
	  user_id,
	  {
	    'start_at': selectInfo.startStr,
	    'stop_at': selectInfo.endStr
	  }));
      },
      selectAllow: function(selectInfo) {
        // constrain to single day
        var new_end_date = new Date(selectInfo.end.getTime() - 1*60000); // -1 minute
        return selectInfo.start.getUTCDate() === new_end_date.getUTCDate();
      },
      eventAllow: function(selectInfo, _draggedEvent) {
        // constrain to single day
        var new_end_date = new Date(selectInfo.end.getTime() - 1*60000); // -1 minute
        return selectInfo.start.getUTCDate() === new_end_date.getUTCDate();
      }
    });
  }

  return $.extend(fcDefaultConfig(initial_date, datesRenderCallback), config);
}

module.exports = { refresh_fullcalendar, redraw_fullcalendar, gotoDate, fcMachineReservationConfig, fcRunReservationConfig, fcTasksConfig, fcQueueReservationConfig };
