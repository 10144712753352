const ongoingTouches = [];

export class LiveCapture extends HTMLElement {

  image_template() {
    return `
        <div class="livecapture-inner">
          <div class="livecapture-actions rounded-pill">
            <button class="livecapture-actions-rotate-camera btn btn-secondary rounded-circle"><i class="fas fa-sync"></i></button>
            <button class="livecapture-actions-capture btn btn-danger rounded-circle" hidden=""><i class="fas fa-camera"></i></button>
            <button class="livecapture-actions-send btn btn-success rounded-circle" hidden=""><i class="fas fa-upload"></i></button>
            <button class="livecapture-actions-close btn btn-secondary rounded-circle" hidden=""><i class="fas fa-times"></i></button>
          </div>
          <div class="livecapture-preview" hidden=""></div>
          <div class="livecapture-area" hidden="">
            <video class="livecapture-stream" muted>Stream nicht verfügbar</video>
          </div>
        </div>
        <input type="file" class="livecapture-input"></input>
    `;
  }

  video_template() {
    return `
        <div class="livecapture-inner">
          <div class="livecapture-actions rounded-pill">
            <button class="livecapture-actions-rotate-camera btn btn-secondary rounded-circle"><i class="fas fa-sync"></i></button>
            <button class="livecapture-actions-capture btn btn-danger rounded-circle" hidden=""><i class="fas fa-circle"></i></button>
            <button class="livecapture-actions-stop btn btn-danger rounded-circle" hidden=""><i class="fas fa-stop"></i></button>
            <button class="livecapture-actions-send btn btn-success rounded-circle" hidden=""><i class="fas fa-upload"></i></button>
            <button class="livecapture-actions-close btn btn-secondary rounded-circle" hidden=""><i class="fas fa-times"></i></button>
          </div>
          <div class="livecapture-preview" hidden=""></div>
          <div class="livecapture-area" hidden="">
            <video class="livecapture-stream" muted>Stream nicht verfügbar</video>
          </div>
        </div>
        <input type="file" class="livecapture-input"></input>
    `;
  }

  audio_template() {
    return `
        <div class="livecapture-inner">
          <div class="livecapture-actions rounded-pill">
            <button class="livecapture-actions-capture btn btn-danger rounded-circle" hidden=""><i class="fas fa-circle"></i></button>
            <button class="livecapture-actions-stop btn btn-danger rounded-circle" hidden=""><i class="fas fa-stop"></i></button>
            <button class="livecapture-actions-send btn btn-success rounded-circle" hidden=""><i class="fas fa-upload"></i></button>
            <button class="livecapture-actions-close btn btn-secondary rounded-circle" hidden=""><i class="fas fa-times"></i></button>
          </div>
          <div class="livecapture-preview" hidden=""></div>
          <div class="livecapture-area" hidden="">
            <audio class="livecapture-stream" muted>Stream nicht verfügbar</audio>
          </div>
        </div>
        <input type="file" class="livecapture-input"></input>
    `;
  }

  drawing_template() {
    return `
        <div class="livecapture-inner">
          <div class="livecapture-actions rounded-pill">
            <div class="btn-toolbar">
            <div class="btn-group btn-group-toggle bg-secondary livecapture-drawing-color" data-toggle="buttons">
              <label class="btn btn-secondary">
                <input type="radio" name="colors" class="livecapture-color-white" value="white">
                <i class="fas fa-circle" style="color:white"></i>
              </label>
              <label class="btn btn-secondary active">
                <input type="radio" name="colors" class="livecapture-color-black" value="black" checked>
                <i class="fas fa-circle" style="color:black"></i>
              </label>
              <label class="btn btn-secondary">
                <input type="radio" name="colors" class="livecapture-color-blue" value="blue">
                <i class="fas fa-circle" style="color:blue"></i>
              </label>
              <label class="btn btn-secondary">
                <input type="radio" name="colors" class="livecapture-color-red" value="red">
                <i class="fas fa-circle" style="color:red"></i>
              </label>
              <label class="btn btn-secondary">
                <input type="radio" name="colors" class="livecapture-color-green" value="green">
                <i class="fas fa-circle" style="color:green"></i>
              </label>
              <label class="btn btn-secondary">
                <input type="radio" name="colors" class="livecapture-color-yellow" value="yellow">
                <i class="fas fa-circle" style="color:yellow"></i>
              </label>
            </div>
            <div class="btn-group btn-group-toggle bg-secondary livecapture-drawing-strokewidth rounded-pill" data-toggle="buttons">
              <label class="btn btn-secondary active">
                <input type="radio" name="strokewidth" value="2" checked>2
              </label>
              <label class="btn btn-secondary">
                <input type="radio" name="strokewidth" value="3">3
              </label>
              <label class="btn btn-secondary">
                <input type="radio" name="strokewidth" value="5">5
              </label>
              <label class="btn btn-secondary">
                <input type="radio" name="strokewidth" value="8">8
              </label>
              <label class="btn btn-secondary">
                <input type="radio" name="strokewidth"value="12">12
              </label>
              <label class="btn btn-secondary">
                <input type="radio" name="strokewidth" value="17">17
              </label>
            </div>
            <button class="livecapture-actions-capture btn btn-success rounded-circle" hidden=""><i class="fas fa-upload"></i></button>
            <button class="livecapture-actions-close btn btn-secondary rounded-circle"><i class="fas fa-times"></i></button>
      </div>
      </div>
          <div class="livecapture-preview" hidden=""></div>
          <div class="livecapture-area" hidden="">
            <canvas class="livecapture-canvas"></canvas>
          </div>
        </div>
        <input type="file" class="livecapture-input"></input>
    `;
  }

  connectedCallback() {
    let startButton = null;
    this.captureFormat = this.getAttribute("data-format");
    this.innerHTML = this[this.captureFormat + "_template"]();


    this.preview = this.querySelector(".livecapture-preview");
    this.inputfield = this.querySelector(".livecapture-input");
    this.liveCaptureArea = this.querySelector('.livecapture-area');
    this.closeButton = this.querySelector(".livecapture-actions-close");
    this.sendButton = this.querySelector(".livecapture-actions-send");
    this.startButton = this.querySelector(".livecapture-actions-capture");
    this.stopButton = this.querySelector(".livecapture-actions-stop");
    this.stream = this.querySelector(".livecapture-stream");

    this.closeButton.addEventListener("click", () => this.close());
    this.sendButton?.addEventListener("click", () => this.dispatchData());

    this["init_" + this.captureFormat]();
  }

  close() {
    this.querySelector(".livecapture-inner").classList.remove("active");
    this.closeButton.hidden = true;
    const event = new CustomEvent("captureclosed");
    this.dispatchEvent(event);
  }

  init_common() {
    this.querySelector(".livecapture-inner").classList.add("active");
    this.closeButton.hidden = false;
    this.querySelector(".livecapture-actions-capture").hidden = false;
  }

  init_media = (mediaParams, setupMediaRecorder) => {
    navigator.mediaDevices
      .getUserMedia(mediaParams)
      .then((media) => {
        this.stream.srcObject = media;
        this.stream.play();
        if (setupMediaRecorder)
          this.initializeMediaRecorder(media);
	      this.liveCaptureArea.hidden = false;
      });
  }

  init_recorder = (mediaParams) => {
    this.init_common();
    this.init_media(mediaParams, true);

    this.stopButton.onclick = (ev) => {
      this.mediaRecorder.stop();
      this.startButton.hidden = false;
      this.stopButton.hidden = true;
      this.preview.hidden = false;
      this.liveCaptureArea.hidden = true;
      this.sendButton.hidden = false;
    }

    this.startButton.onclick = (ev) => {
      this.mediaRecorder.start();
      this.startButton.hidden = true;
      this.stopButton.hidden = false;
      this.preview.hidden = true;
      this.liveCaptureArea.hidden = false;
      this.sendButton.hidden = true;
    }
  }
  init_audio = () => this.init_recorder({video: false, audio: true});
  init_video = () => {
    this.rotateCameraButton = this.querySelector('.livecapture-actions-rotate-camera');
    this.rotateCameraButton.addEventListener('click', () => {
      let facingmode = (this.stream.srcObject.getVideoTracks()[0].getConstraints().facingMode) == "environment" ? "user" : "environment";
      this.init_media({ video: { facingMode: facingmode, width: { min: 1024, ideal: 1920, max: 1920 }}, audio: true}, true);
    });
    this.init_recorder({ video: { facingMode: "environment",
			       width: { min: 1024, ideal: 1920, max: 1920 } },
		      audio: true });
  };

  init_drawing = async () => {
    this.init_common();
    var canvas = this.querySelector(".livecapture-canvas");
    this.liveCaptureArea.hidden = false;
    const ctx = canvas.getContext("2d");
    const dataSrc = this.getAttribute("data-src")
    if (dataSrc) {
      //load background image
      canvas.width = this.liveCaptureArea.clientWidth;
      canvas.height = this.liveCaptureArea.clientHeight;
      let b = await createImageBitmap(await (await fetch(dataSrc)).blob());
      let scaling = Math.min(1,Math.min(canvas.width/b.width, canvas.height/b.height));
      canvas.width = scaling*b.width;
      canvas.height = scaling*b.height;
      ctx.drawImage(b,0,0, scaling*b.width, scaling*b.height);
    } else {
      //load white background
      canvas.width = this.liveCaptureArea.clientWidth;
      canvas.height = this.liveCaptureArea.clientHeight;
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
    this.startButton
      .onclick = async (ev) => {
        var canvas = this.querySelector(".livecapture-canvas");
	      canvas.toBlob((blob) => {
	        this.updateInputFile(blob, "snapshot.png", "image/png");
          this.dispatchData();
        });
      }

    let isDrawing = false;
    let x = 0;
    let y = 0;
    var offsetX;
    var offsetY;

    let drawLine = (context, x1, y1, x2, y2) => {
      context.beginPath();
      context.strokeStyle = this.querySelector('.livecapture-drawing-color input:checked').value;
      context.lineWidth = parseInt(this.querySelector('.livecapture-drawing-strokewidth input:checked').value);
      context.lineJoin = "round";
      context.moveTo(x1, y1);
      context.lineTo(x2, y2);
      context.closePath();
      context.stroke();
    }

    const ongoingTouches = [];

    const handleStart = (evt) => {
      evt.preventDefault();
      const touches = evt.changedTouches;
      offsetX = canvas.getBoundingClientRect().left;
      offsetY = canvas.getBoundingClientRect().top;
      for (let i = 0; i < touches.length; i++) {
        ongoingTouches.push(copyTouch(touches[i]));
      }
    }

    const handleMove = (evt) => {
      evt.preventDefault();
      const touches = evt.changedTouches;
      for (let i = 0; i < touches.length; i++) {
	const color = this.querySelector('.livecapture-drawing-color input:checked').value;
	const idx = ongoingTouchIndexById(touches[i].identifier);
	if (idx >= 0) {
          drawLine(ctx,
		   ongoingTouches[idx].clientX - offsetX,
		   ongoingTouches[idx].clientY - offsetY,
		   touches[i].clientX - offsetX,
		   touches[i].clientY - offsetY);
          ongoingTouches.splice(idx, 1, copyTouch(touches[i]));  // swap in the new touch record
	}
      }
    }

    const handleEnd = (evt) => {
      evt.preventDefault();
      const touches = evt.changedTouches;
      for (let i = 0; i < touches.length; i++) {
	//const color = this.querySelector('.livecapture-drawing-color input:checked').value;
	let idx = ongoingTouchIndexById(touches[i].identifier);
	if (idx >= 0) {
          //ctx.lineWidth = parseInt(this.querySelector('.livecapture-drawing-strokewidth input:checked').value);
          //ctx.fillStyle = color;
          ongoingTouches.splice(idx, 1);  // remove it; we're done
	}
      }
    }

    const handleCancel = (evt) => {
      evt.preventDefault();
      const touches = evt.changedTouches;
      for (let i = 0; i < touches.length; i++) {
	let idx = ongoingTouchIndexById(touches[i].identifier);
	ongoingTouches.splice(idx, 1);  // remove it; we're done
      }
    }

    const copyTouch = ({ identifier, clientX, clientY }) => {
      return { identifier, clientX, clientY };
    }

    const ongoingTouchIndexById = (idToFind) => {
      for (let i = 0; i < ongoingTouches.length; i++) {
	const id = ongoingTouches[i].identifier;
	if (id === idToFind) {
	  return i;
	}
      }
      return -1;    // not found
    }

    canvas.addEventListener('touchstart',  handleStart);
    canvas.addEventListener('touchend',    handleEnd);
    canvas.addEventListener('touchcancel', handleCancel);
    canvas.addEventListener('touchmove',   handleMove);

    canvas.addEventListener('mousedown', (e) => {
      x = e.offsetX;
      y = e.offsetY;
      isDrawing = true;
    });

    canvas.addEventListener('mousemove', (e) => {
      if (isDrawing) {
        drawLine(ctx, x, y, e.offsetX, e.offsetY);
        x = e.offsetX;
        y = e.offsetY;
      }
    });

    canvas.addEventListener('mouseup', (e) => {
      if (isDrawing) {
        drawLine(ctx, x, y, e.offsetX, e.offsetY);
        x = 0;
        y = 0;
        isDrawing = false;
      }
    });
  };

  init_image() {
    this.init_common();
    var canvas = null;
    this.rotateCameraButton = this.querySelector('.livecapture-actions-rotate-camera');
    this.rotateCameraButton.addEventListener('click', (ev) => {
      let facingmode = (this.stream.srcObject.getVideoTracks()[0].getConstraints().facingMode) == "environment" ? "user" : "environment";
      this.init_media({ video: { facingMode: facingmode, width: { min: 1024, ideal: 1920, max: 1920 }}}, false)
    });
    this.stream.addEventListener("loadedmetadata", () => {
      canvas = new OffscreenCanvas(this.stream.videoWidth, this.stream.videoHeight);
    });
    this.init_media({ video: { facingMode: "environment",
			       width: { min: 1024, ideal: 1920, max: 1920 }}}, false);

    this.startButton
      .onclick = async (ev) => {
	const ctx = canvas.getContext('2d');
	ctx.drawImage(this.stream, 0, 0, canvas.width, canvas.height);
	let blob = await canvas.convertToBlob();
	this.updateInputFile(blob, "snapshot.png", "image/png");
	this.preview.hidden = false;
	this.liveCaptureArea.hidden = true;
	this.sendButton.hidden = false;
      }
  }

  showPreview() {
    let el;
    switch (this.getAttribute("data-format")) {
    case 'image':
    case 'drawing':
      el = document.createElement('img');
      break;
    case 'video':
      el = document.createElement('video');
      el.controls = true;
      break;
    case 'audio':
      el = document.createElement('audio');
      el.controls = true;
      break;
    }
    el.src = URL.createObjectURL(this.inputfield.files[0]);

    this.preview.innerHTML = '';
    this.preview.appendChild(el);
  }

  dispatchData() {
    const event = new CustomEvent("capturetaken", {
      detail: { file: this.inputfield.files[0] }
    });
    this.dispatchEvent(event);
    this.close();
  }

  updateInputFile(blob, filename, filetype) {
    let file = new File([blob], filename, { type: filetype });
    let d = new DataTransfer();
    d.items.add(file);
    this.inputfield.files = d.files;
    this.showPreview();
  }

  initializeMediaRecorder(stream) {
    let media = new MediaRecorder(stream);
    this.chunks = [];
    media.addEventListener("dataavailable", (e) => this.chunks.push(e.data));
    media.addEventListener("stop", () => {
      if (this.getAttribute("data-format") == "video") {
        var blob = new Blob(this.chunks, { 'type' : 'video/ogv; codecs=opus' });
        this.updateInputFile(blob, "video.ogv", "video/ogv");
      } else {
        var blob = new Blob(this.chunks, { 'type' : 'audio/ogg' });
        this.updateInputFile(blob, "audio.ogg", "audio/ogg");
      }
    });
    this.mediaRecorder = media;
  }
}

customElements.define('live-capture', LiveCapture);
