function init_ajax_forms(container) {
  if (!container) {
    var container = $(document.body);
  }

  $(".modal-content button.btn-primary[type='submit']").off("click");
  $(".modal-content button.btn-primary[type='submit']").bind("click", function(event) {
    event.stopPropagation();
    Rails.fire($(this).closest(".modal-content").find('form')[0], 'submit')
  });

  // generic ajax form
  $("form[data-ajax=true], a[data-remote=true]", container).each(function() {

    //FIXME: Funktioniert das wirklich in allen Fällen?
    // if (has_events(this)) return;

    var el = $(this);
    // prevent parent clickables to fire
    // (not possible with preventPropagation() if using rails-ujs)
    el.bind("click", function(event) { $(this).closest(".clickable").off("click"); });
    // if (this.nodeName == "A")
      // el.bind("click", function(event) { alert("testi"); event.stopPropagation(); });
    el.bind("ajax:success", success_callback);
    el.bind("ajax:error", error_callback);
    el.bind("ajax:before", function(event) {
      $(this).clear_previous_errors();
    });
  });

  function success_callback(event) {
    var data = event.detail[0];
    if (data.error)
      notify("error", data.error);
    if (data.notice)
      notify("success", data.notice);
    if (data.redirect)
      window.location = data.redirect;
    if (data.reload)
      window.location.reload();
    if (data.replace) {
      for (var id in data.replace) {
        if ($(id).length > 0) {
          $('[data-toggle="tooltip"]', $(id)).tooltip('dispose');
          $(id).replaceWith(data.replace[id]);
          common.init_js_controls($(id).parent());
        }
      }
    }
    if (data.replace_remote) {
      replace_remote(data.replace_remote);
    }
    // FIXME: use ID selector for replacement, otherwise init_js_controls is not called
    if (data.replace_inner) {
      for (var id in data.replace_inner) {
        if ($(id).length > 0) {
          $('[data-toggle="tooltip"]', $(id)).tooltip('dispose');
          $(id).html(data.replace_inner[id]);
          common.init_js_controls($(id));
        }
      }
    }
    if (data.remove) {
      for (var selector of data.remove) {
        $(selector).remove();
      }
    }
    if (data.js) {
      eval(data.js);
    }
  };

  function error_callback(event) {
    var xhr = event.detail[2];
    if (xhr.status === 422) {
      var data = event.detail[0];
      /* FIXME: object should not have a field with the name 'error' */
      if (data.error)
	notify("error", data.error);
      else
	$(this).render_form_errors(data);
    } else if (xhr.status === 413)  {
      alert("Die Datei ist zu groß, um hochgeladen zu werden. Bitte wählen Sie eine kleinere!");
    } else {
      alert("Error " + xhr.status + ": " + xhr.statusText + " (Sorry, something does not work. Please contact the administrator.)");
    }
  }

  // machine_process operators
  $('#reservation_machine_process_id')
    .bind('ajax:before', function() {
      $(this).attr("data-params", $('form.reservation_form').serialize());
    })
    .bind('ajax:success', function(event) {
      event.stopPropagation();
      var data = event.detail[0];
      $("#machine_process_form").html(data.body.innerHTML);
      common.init_js_controls($('#machine_process_form'));
    });

  // reservation assigned users
  $('#reservation_assigned_user_ids')
    .bind('ajax:before', function() {
      $(this).attr("data-params", $('form.reservation_form').serialize());
    })
    .bind('ajax:success', function(event) {
      event.stopPropagation();
      var data = event.detail[0];
      $("#assigned_users_form").html(data.body.innerHTML);
      common.init_js_controls($('#assigned_users_form'));
    });

  // reservation form
  container.find('form.reservation_form, .delete-reservation')
    .bind('submit', function() {
      var operator_input = 'form.reservation_form input[name="reservation[user_id]"]';
      // check if there are operators for the process
      if ($(operator_input).length < 3)
	return true;

      if ($(operator_input + ':checked').val() == "")
	return confirm("Kein Operator gewählt. Das macht nur Sinn, wenn ein Prozess gleichzeitig mit einem anderen gemacht wird. Oder wenn das Gerät tatsächlich ohne Operator läuft. Sind Sie sicher?");
      return true;
    })
    .bind("ajax:success", function(event) {
      common.close_modal_refresh_calendar();
    });
  // update state buttons in show
  $(".update-reservation-state")
    .bind("ajax:success", function(event) {
      common.close_modal_refresh_calendar();
    });

  // task form
  $("form.task_form, .update-task-state, .delete-task")
    .bind("ajax:success", function(event) {
      common.close_modal_refresh_calendar();
    });

  $("form.task_form #task_run_id").change( function() {
    if ($('#task_run_id').val() === '') {
      $('#costcenter_select').show();
    } else {
      $('#task_costcenter_id').val('');
      $('#costcenter_select').hide();
    }
  });

  $("form.task_form #regular_select_fields #task_costcenter_id")
    .change( function() {
      if ($('#regular_select_fields #task_costcenter_id').val() === '') {
	$('#run_select').show();
      } else {
	$('#task_run_id').val('');
	$('#run_select').hide();
      }
    });

  $('form.task_form #task_is_absence').change( function() {
    if(this.checked) {
      $('#regular_select_fields').hide();
      $('#regular_comment').hide();
      $("#regular_comment textarea").attr('disabled','true');
      $('#absence_select_fields').show();
      $('#absence_comment').show();
      $("#absence_comment textarea").removeAttr('disabled');
    } else {
      $('#absence_select_fields').hide();
      $('#absence_comment').hide();
      $("#absence_comment textarea").attr('disabled','true');
      $('#regular_select_fields').show();
      $('#regular_comment').show();
      $("#regular_comment textarea").removeAttr('disabled');
    }
  });

  $('#absence_select_fields > .form-group > select').change( function() {
    toggle_absence_costcenter_select();
   });

  if ($('form.task_form #task_is_absence').length > 0 &&
      $('form.task_form #task_is_absence')[0].checked) {
    $('#regular_select_fields').hide();
    $('#absence_select_fields').show();
    toggle_absence_costcenter_select();
  }

  $('form.task_form #absence_costcenter').change( function() {
    // FIXME ugly hack because there are two selects (one hidden)
    $('#task_costcenter_id').val($('#absence_costcenter').val());
  });

  if ($('form.new_absence').length > 0  || $('form.edit_absence').length > 0) {
    $('#absence_select_fields').show();
    toggle_absence_costcenter_select();
  }

  // new recipe / process_item created from machine_process form
  $("form.new_process_item_form")
    .bind("ajax:success", function(event) {
      var data = event.detail[0];
      if ($("form.machine_process_form").length) {
        $('#machine_process_process_item').append(
            $('<option></option>').val(data["process_item_id"]).html(data["process_item_name"]).attr('selected', true)
            );
        common.close_modal(this);
      } else {
        window.location.reload();
      }
    });

  $("form.new_recipe_form")
    .bind("ajax:success", function(event) {
      var data = event.detail[0];
      if ($("form.machine_process_form").length) {
        $('#machine_process_recipes').append(
            $('<option></option>').val(data["recipe_id"]).html(data["recipe_name"]).attr('selected', true)
            );
        common.close_modal(this);
      } else {
        window.location.reload();
      }
    });

  // new parameter created from parameter_group form
  $("form.new-parameter-form")
    .bind("ajax:success", function(event) {
      var data = event.detail[0];
      if ($("form.machine_process_form").length) {
        $('#parameter-group-parameters').append(
            $('<option></option>').val(data["parameter_id"]).html(data["parameter_name"]).attr('selected', true)
            );
      } else {
        window.location.reload();
      }
    });

  // new parameter group created from recipe form
  $("form.new-parameter-group-form")
    .bind("ajax:success", function(event) {
      var data = event.detail[0];
      if ($("form.machine_process_form").length) {
        $('#recipe-parameter-group').append(
            $('<option></option>').val(data["parameter_id"]).html(data["parameter_name"]).attr('selected', true)
            );
      } else {
        window.location.reload();
      }
    });

  // close modal after successfull update
  $(".modal form.close-on-success")
    .bind("ajax:success", function(event) {
      common.close_modal(this);
    });
};

// Check if element (HTML element not jquery) has attached events
function has_events(el) {
  return $._data(el, 'events') !== undefined;
}

function toggle_absence_costcenter_select() {
  var needed = $('#absence_select_fields').attr('data-costcenter-needed').split(',');
  var id = $('#absence_select_fields > .form-group > select').val();
  if ($.inArray(id, needed) != -1) {
    $('#absence_costcenter_select').show();
  } else {
    $('#absence_costcenter_select').hide();
  }
};

(function($) {

  $.fn.render_form_errors = function(errors) {
    console.log(errors);
    var model = this.data('model');
    this.clear_previous_errors();
    $.each(errors, function(field, messages) {
      if (add_error_message(model, field, messages))
	return;
      // try with id
      if (add_error_message(model, field + "_id", messages))
        return;
      // try with ids
      if (!add_error_message(model, field.slice(0, -1) + "_ids", messages))
	alert("In diesem Formular konnte das Feld '"+ model +"|"+ field +"' nicht gefunden werden! Am besten dieses Formular neu laden und falls der Fehler weiterhin auftritt den Administrator verständigen. \nDanke. \n\nDer ursprüngliche Fehler war: " + messages.join(', '));
    });
  };

  $.fn.clear_previous_errors = function() {
    $('.form-group.has-error', this).each(function() {
      $(this).removeClass('has-error');
      $('.help-block', $(this)).html('');
    });
  };

}(jQuery));

function replace_remote(ids) {
  ids.forEach(function(id){
    if ($(id).length > 0) {
      $('[data-toggle="tooltip"]', $(id)).tooltip('dispose');
      var url = $(id).data("url");
      $.get(url, function(html) {
        $(id).replaceWith(html);
        common.init_js_controls($(id));
      });
    }
  });
}

function add_error_message(name, field, messages) {
  console.log(field)
  console.log(messages)
  var keys = field.split("|");
  keys.forEach(function(key) {
    name += '[' + key + ']';
  });
  // ^= is a "starts with" selector because input name maybe followed by "[]" for arrays
  var $input = $('[name^="' + name + '"]');
  if ($input.length == 0) return false;

  var error_block = $input.closest('.form-group').addClass('has-error').find('.help-block');
  if (!error_block.length) {
    if ($input.closest('.form-group.row').length) {
      // horizontal forms
      $input.closest('.form-group.row > div').append("<span class='help-block'></span>");
    } else {
      $input.closest('.form-group').append("<span class='help-block'></span>");
    }
    error_block = $input.closest('.form-group').find('.help-block');
  }
  error_block.html(messages.join(', '));
  return true;
};

function notify(notify_type, msg) {
  var alert_div = $('#ajax-feedback div');
  alert_div.removeClass();
  alert_div.empty();
  alert_div.addClass("alert");

  var delay = 1500; /* default delay */
  if (notify_type == 'processing') {
    alert_div.append('<i class="fa fa-spinner fa-spin"></i> ');
    alert_div.addClass('alert-primary');
  } else if (notify_type == 'success') {
    alert_div.append('<i class="fa fa-check"></i> ');
    alert_div.addClass('alert-success');
  } else if (notify_type == 'post') {
    delay = 5000;
    alert_div.append('<i class="fa fa-info"></i> ');
    alert_div.addClass('alert-info');
  } else if (notify_type == 'error') {
    delay = 5000;
    alert_div.append('<i class="fa fa-exclamation-triangle"></i> ');
    alert_div.addClass('alert-danger');
  }
  alert_div.append(msg);
  alert_div.slideDown(300).delay(delay).slideUp(200);
}

module.exports = { init_ajax_forms, notify, replace_remote };
